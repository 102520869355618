import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import {Label} from 'common/components/Label';
import {useMutation} from 'react-query';
import {signInCustomerRequest} from 'api/customer.api';
import {Icon} from 'shared/icon/icon.component';
import {useRecaptcha} from 'common/hooks';
import {CountryCode} from 'utils/types';
import {parsePhoneNumber} from 'react-phone-number-input';

interface SendVerificationCodeFormFields {
  phone: string;
}

interface SendVerificationCodeFormProps extends Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'> {
  onSubmitted?: (formData: Omit<SendVerificationCodeFormFields, 'recaptchaToken'>) => void;
  defaultValues?: Pick<SendVerificationCodeFormFields, 'phone'>;
}

export const SendVerificationCodeForm = React.forwardRef<HTMLFormElement, SendVerificationCodeFormProps>(
  ({onSubmitted, defaultValues, ...formProps}, ref) => {
    const {formState, handleSubmit, control, reset} = useForm<SendVerificationCodeFormFields>({
      defaultValues,
    });

    const {} = useRecaptcha();

    const sendVerificationCodeMutation = useMutation<{verificationCodeExpiration: string}, Error, {phone: string}>({
      mutationFn: async (body) => {
        // const recaptchaToken = await executeRecaptcha('send_verification_code');
        // return signInCustomerRequest({...body, 'g-recaptcha-response': recaptchaToken});
        return signInCustomerRequest({...body, 'g-recaptcha-response': ''});
      },
      mutationKey: 'send-verification-code',
      onSuccess: (_, requestPayload) => {
        onSubmitted?.(requestPayload);
      },
      onError: (error) => {
        console.error(error);
      },
    });

    const onSubmit = (formData: SendVerificationCodeFormFields) => sendVerificationCodeMutation.mutate({phone: formData.phone});

    const onReset = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      reset({phone: ''});
    };

    const localStorage = JSON.parse(window.localStorage.getItem('APP_STATE') ?? '');
    const companyName = localStorage?.location?.section?.store?.company?.name;

    const isRestrictedCompany = companyName === 'LDHG';
    const allowedCountries = isRestrictedCompany ? ['US', 'CA'] : undefined;

    return (
      <form ref={ref} onReset={onReset} onSubmit={handleSubmit(onSubmit)} {...formProps}>
        <div className={classNames('kl-input-group', {'kl-input-group-danger': formState.errors.phone})}>
          <Label htmlFor="phone" className="tw-sr-only">
            Phone number
          </Label>
          <div className="tw-mt-1">
            <Controller
              name="phone"
              control={control}
              rules={{
                validate: (value) => {
                  const phoneNumber = parsePhoneNumber(value);
                  const phoneCountry = phoneNumber?.country;
                  if (phoneCountry) {
                    if (isRestrictedCompany && !allowedCountries?.includes(phoneCountry)) {
                      return 'Invalid phone number.';
                    }
                    return true;
                  }
                },
                required: 'Phone number is required.',
              }}
              render={({field: {onChange, value}}) => (
                <PhoneInput
                  placeholder="Type in your phone number"
                  name="phone"
                  value={value}
                  onChange={onChange}
                  defaultCountry="US"
                  addInternationalOption={!isRestrictedCompany}
                  countries={allowedCountries as CountryCode[]}
                  countryCallingCodeEditable={!isRestrictedCompany}
                />
              )}
            />
          </div>
          {formState.errors.phone ? <p className="kl-input-helper">{formState.errors.phone.message}</p> : null}
        </div>

        {sendVerificationCodeMutation.isError && (
          <div className="kl-callout kl-callout-danger">
            <Icon icon="error" />
            <div className="kl-callout-body">An error ocurred sending the verification code</div>
          </div>
        )}
      </form>
    );
  }
);

SendVerificationCodeForm.displayName = 'SendVerificationCodeForm';
