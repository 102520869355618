import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import VerificationInput from 'react-verification-input';

import classNames from 'classnames';

import {useMutation} from 'react-query';

import {verifyCustomerCodeRequest} from 'api/customer.api';
import {Customer, CustomerVerifyCodeRequest} from 'models/customer.model';

import {tw} from 'common/utils';
import {useAppContext} from 'contexts/app.context';

import {useRecaptcha} from 'common/hooks';

interface VerifyCodeFormFields {
  verificationCode: string;
  phone: string;
}

interface VerifyCodeFormProps extends React.HTMLProps<HTMLFormElement> {
  onSubmitted: (formData: Omit<VerifyCodeFormFields, 'recaptchaToken'>) => void;
  defaultValues: Partial<VerifyCodeFormFields>;
}

export const VerifyCodeForm = React.forwardRef<HTMLFormElement, VerifyCodeFormProps>(
  ({onSubmitted, defaultValues, ...formProps}, ref) => {
    const verificationCodeInputRef = React.useRef<HTMLInputElement>(null);
    const {} = useRecaptcha();
    const {setContextState} = useAppContext();
    const {control, handleSubmit, formState, setValue, setError, clearErrors} = useForm<VerifyCodeFormFields>({
      defaultValues,
      mode: 'onBlur',
    });

    const verifyCodeMutation = useMutation<Customer, Error, Omit<CustomerVerifyCodeRequest, 'g-recaptcha-response'>>({
      mutationKey: 'verify-code',
      mutationFn: async (body) => {
        // const recaptchaToken = await executeRecaptcha('verify_code');
        // return verifyCustomerCodeRequest({...body, 'g-recaptcha-response': recaptchaToken});
        return verifyCustomerCodeRequest({...body, 'g-recaptcha-response': ''});
      },
      onSuccess: (customer, requestPayload) => {
        setContextState({customer});
        onSubmitted(requestPayload);
      },
      onError: (error) => {
        console.error(error);
        setError('verificationCode', {message: 'Invalid verification code'});
        setValue('verificationCode', '');
      },
    });

    const onSubmit = async (form: VerifyCodeFormFields) =>
      !verifyCodeMutation.isLoading && verifyCodeMutation.mutate({verificationCode: form.verificationCode, phone: form.phone});

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames('', {'kl-input-group-danger': verifyCodeMutation.isError})}
        {...formProps}
      >
        <Controller
          name="verificationCode"
          control={control}
          rules={{required: true, minLength: 4, maxLength: 4}}
          render={(props: any) => (
            <VerificationInput
              ref={verificationCodeInputRef}
              length={4}
              validChars="z0-9"
              inputProps={{autoComplete: 'one-time-code'}}
              placeholder=""
              value={props.field.value}
              onChange={(values) => {
                clearErrors();
                props.field.onChange(values);
              }}
              classNames={{
                container: 'tw-h-10 tw-w-48',
                character: tw`tw-flex tw-items-center tw-justify-center tw-text-sm tw-bg-white tw-rounded-md tw-border tw-border-gray-300 tw-bg-transparent tw-py-2 tw-px-3 placeholder:tw-text-slate-400 `,
                characterInactive: '',
                characterSelected: 'tw-outline-none tw-ring-2 tw-ring-blue-400 tw-ring-offset-2',
              }}
            />
          )}
        />
        {formState.errors.verificationCode && (
          <p className="tw-text-sm tw-text-red-500 tw-mt-2">The code is invalid. Try again.</p>
        )}
        {/* <GoogleReCaptcha key={Date.now()} onVerify={onRecaptchaTokenVerify} /> */}
      </form>
    );
  }
);

VerifyCodeForm.displayName = 'VerifyCodeForm';
